import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Header from "../components/Header";
import PostList from '../components/PostList'

const PostsPage = ({ data }) => {
  console.log("got graphql data:", data);

  return (
    <main className="flex flex-row flex-wrap w-full h-full bg-white text-black">
      <Header large={false} />

      <PostList nodes={data.allMdx.nodes}/>
    </main>
  );
};

export const query = graphql`
  query PostQuery {
    allMdx(
      filter: { frontmatter: { templateKey: { in: ["updates"] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        body
        slug
        frontmatter {
          description
          title
          date(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;

export default PostsPage;
